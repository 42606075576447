<!-- 产品追溯表 -->
<template>
  <div class="pagePadding">
    <span class="pageBtn finger btnReset" @click="back">返回</span>
    <span class="pageBtn finger btnSure marginL20">
      <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
      <span v-if="Loagin" class="pageBtn finger btnSure">
        <Spin v-if="Loagin" style="display: inline-block; color: white;">
          <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
        </Spin>
      </span>
    </span>
    <div style="margin-top: 20px;" class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :productList="listColumns" show-summary :summary-method="handleSummary" :productData="listData" :pages="queryFrom" :total="total" totalText="条记录" :isLoad="isLoad" @change-page="changePage"></Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'monthlyProductStatisticsDetail',
  components: {
    Table,
  },
  data() {
    return {
      Loagin: false,
      purchase_quantity_tatol: '',
      profit_total: '',
      purchase_amount_tatol: '',
      sale_amount_tatol: '',
      sale_quantity_tatol: '',
      supplied_varieties_tatol: '',
      total_no_tax_purchase_amount: '',
      total_no_tax_sale_amount: '',
      options: {},
      tabloading: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          key: 'index',
          align: 'center',
          minWidth: 60,
          maxWidth: 70,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 130,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '时间',
          key: 'create_time',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '供货总量',
          key: 'purchase_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '供货金额',
          key: 'purchase_amount',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.purchase_amount ? '￥' + param.row.purchase_amount : 0)])
          },
        },
        {
          title: '供货不含税金额',
          key: 'no_tax_purchase_amount',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.no_tax_purchase_amount ? '￥' + param.row.no_tax_purchase_amount : 0)])
          },
        },
        {
          title: '采购比重',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let money
            if (!param.row.purchase_quantity) {
              money = ''
            } else {
              money = ((Number(param.row.purchase_quantity) / this.purchase_quantity_tatol) * 100).toFixed(4)
            }
            money = money ? money : 0
            return h('span', money + '%')
          },
        },
        {
          title: '销售总量',
          key: 'sale_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '销售金额',
          key: 'sale_amount',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.sale_amount ? '￥' + param.row.sale_amount : 0)])
          },
        },
        {
          title: '销售不含税金额',
          key: 'no_tax_sale_amount',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.no_tax_sale_amount ? '￥' + param.row.no_tax_sale_amount : 0)])
          },
        },
        {
          title: '利润',
          key: 'profit',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.profit ? '￥' + param.row.profit : 0)])
          },
        },
        {
          title: '利润率',
          key: 'profit_margin',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 70,
          maxWidth: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.getDetailsData(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 产品下拉
      productList: [],
      // 加载中
      isLoad: false,
      // 总条数
      total: 0,
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,
        product_name: '',
      },
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      this.$http.downFile(this.$api.exportProductSupplySec, this.queryFrom, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '产品月度统计.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    back() {
      this.$router.push({
        path: '/monthlyProductStatistics',
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          let v = ''
          if (key === 'purchase_quantity' || key === 'profit' || key === 'purchase_amount' || key === 'sale_amount' || key === 'sale_quantity' || key === 'supplied_varieties' || key === 'no_tax_purchase_amount' || key === 'no_tax_sale_amount') {
            if (key === 'purchase_quantity') {
              v = this.purchase_quantity_tatol
            }
            if (key === 'profit') {
              v = '¥' + this.profit_total
            }
            if (key === 'purchase_amount') {
              v = '¥' + this.purchase_amount_tatol
            }
            if (key === 'sale_amount') {
              v = '¥' + this.sale_amount_tatol
            }
            if (key === 'sale_quantity') {
              v = this.sale_quantity_tatol
            }
            if (key === 'no_tax_purchase_amount') {
              v = '¥' + this.total_no_tax_purchase_amount
            }
            if (key === 'no_tax_sale_amount') {
              v = '¥' + this.total_no_tax_sale_amount
            }
          }
          sums[key] = {
            key,
            value: v,
          }
        } else {
          let v = ''
          if (key === 'purchase_quantity') {
            v = this.purchase_quantity_tatol
          }
          if (key === 'profit') {
            v = '¥' + this.profit_total
          }
          if (key === 'purchase_amount') {
            v = '¥' + this.purchase_amount_tatol
          }
          if (key === 'sale_amount') {
            v = '¥' + this.sale_amount_tatol
          }
          if (key === 'sale_quantity') {
            v = this.sale_quantity_tatol
          }
          if (key === 'no_tax_purchase_amount') {
            v = '¥' + this.total_no_tax_purchase_amount
          }
          if (key === 'no_tax_sale_amount') {
            v = '¥' + this.total_no_tax_sale_amount
          }
          sums[key] = {
            key,
            value: v,
          }
        }
      })
      return sums
    },
    // 查询
    query() {
      this.queryFrom.page = 1
      this.getProductTrace()
    },
    // 获取详情数据
    getDetailsData(row) {
      this.$router.push({
        path: '/goDocumentNum',
        query: {
          licence_code: row.licence_code,
          create_time: row.create_time,
          product_name: row.product_name,
        },
      })
    },
    // 翻页
    changePage(e) {
      this.queryFrom.page = e
      this.getProductTrace()
    },
    // 获取数据
    getProductTrace() {
      this.tabloading = true
      this.queryFrom.licence_code = this.$route.query.licence_code
      this.queryFrom.product_name = this.$route.query.product_name
      this.queryFrom.end_time = this.$route.query.end_time ? this.$route.query.end_time : ''
      this.queryFrom.begin_time = this.$route.query.begin_time ? this.$route.query.begin_time : ''
      this.$http.get(this.$api.productSupplySec, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data.result
        this.total = res.data.total
      })
      this.$http.get(this.$api.ProductSupplySecTotal, this.queryFrom, false).then(res => {
        this.purchase_quantity_tatol = res.data.purchase_quantity_tatol
        this.profit_total = res.data.profit_total
        this.purchase_amount_tatol = res.data.purchase_amount_tatol
        this.sale_amount_tatol = res.data.sale_amount_tatol
        this.sale_quantity_tatol = res.data.sale_quantity_tatol
        this.supplied_varieties_tatol = res.data.supplied_varieties_tatol
        this.total_no_tax_purchase_amount = res.data.total_no_tax_purchase_amount
        this.total_no_tax_sale_amount = res.data.total_no_tax_sale_amount
      })
    },
  },
}
</script>

<style scoped lang="less">
.modal {
  color: #333333;
  .header {
    font-size: 18px;
    font-weight: bold;
  }
  .body {
    p {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .info {
      display: flex;
      .left {
        flex: 6;
      }
      .right {
        flex: 4;
      }
      .item {
        display: flex;
        font-size: 16px;
        line-height: 36px;
        margin-bottom: 20px;
        .title {
          min-width: 70px;
          text-align-last: justify;
        }
      }
    }
  }
}
.marginL20 {
  margin-left: 20px;
}
</style>
